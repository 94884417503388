import React, { useEffect, useState } from "react";

import { ReactComponent as ArrowIcon } from "../../components/icons/arrow_icon.svg";
import OverFlowToolTip from "../common/OverFlowToolTip";
import ProjectTable from "./ProjectTable";
import { ReactComponent as TableLargeIcon } from "../../components/icons/table_large_icon.svg";
import { ReactComponent as TableMediumIcon } from "../../components/icons/table_medium_icon.svg";
import { ReactComponent as TableSmallIcon } from "../../components/icons/table_small_icon.svg";
import { Tooltip } from "bootstrap/dist/js/bootstrap.js";
import actionType from "../../constants/actionType";
import claims from "../../constants/claims";
import forecastViewType from "../../constants/forecastViewType";
import { formatNumber } from "../common/formatHelper";
import { isTrue } from "../common/utilities";
import tableRowSize from "../../constants/tableRowSize";

function ForecastTable({
  data,
  config,
  handleEdit,
  currentView,
  forecastRequest,
  isUserAuthorized,
}) {
  const [showProjectTable, setShowProjectTable] = useState([]);
  const [selectedProjectRow, setSelectedProjectRow] = useState();
  const [currentTableSize, setCurrentTableSize] = useState(
    isTrue(config.enableForecastTableSizeSelection) &&
      localStorage.getItem("tablesize")
      ? localStorage.getItem("tablesize")
      : tableRowSize.Large
  );

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  });

  const handleTableSizeSelection = (value) => {
    localStorage.setItem("tablesize", value);

    setCurrentTableSize(value);
    for (const tooltipElement of document.querySelectorAll(
      '[role="tooltip"]'
    )) {
      tooltipElement.remove();
    }
  };

  const getTableSizeCss = () => {
    switch (currentTableSize) {
      case tableRowSize.Small:
        return "table-sm";
      case tableRowSize.Medium:
        return "table-md";
      case tableRowSize.Large:
        return "table-lg";
      default:
        return "table-lg";
    }
  };

  const handleShowProjects = (c) => {
    if (!c) {
      return;
    }

    setSelectedProjectRow(c);

    setShowProjectTable((previousSelections) => {
      const match = previousSelections.find(
        (p) => p.attributeName === c.attributeName
      )?.value;
      return [
        ...previousSelections.filter(
          (p) => p.attributeName !== c.attributeName
        ),
        {
          attributeName: c.attributeName,
          value: !match,
        },
      ];
    });
  };

  const isProjectRowExpanded = (c) => {
    return showProjectTable.find((p) => p.attributeName === c.attributeName)
      ?.value;
  };

  const getForecastHeaderTemplate = () => {
    return config.totalTableHeader != null ||
      isTrue(config.enableForecastTableSizeSelection) ? (
      <div className="flex">
        <h4 className="pt-3 ps-4">
          {config.totalTableHeader != null ? config.totalTableHeader : null}
        </h4>
        {isTrue(config.enableForecastTableSizeSelection) ? (
          <div className="pt-3 flex flex-end">
            <div className="switch-container me-3">
              <fieldset className="switch-fieldset">
                <div className="switch-group">
                  <label className="switch-wrap">
                    <input
                      type="radio"
                      name="tableSizeLarge"
                      key="tableSizeLarge"
                      checked={currentTableSize === tableRowSize.Large}
                      onChange={(e) =>
                        handleTableSizeSelection(tableRowSize.Large)
                      }
                    />
                    <div
                      className="switch switch-small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Large table"
                    >
                      <TableLargeIcon />
                    </div>
                  </label>
                  <label className="switch-wrap">
                    <input
                      type="radio"
                      name="tableSizeMedium"
                      key="tableSizeMedium"
                      checked={currentTableSize === tableRowSize.Medium}
                      onChange={(e) =>
                        handleTableSizeSelection(tableRowSize.Medium)
                      }
                    />
                    <div
                      className="switch switch-small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Medium table"
                    >
                      <TableMediumIcon />
                    </div>
                  </label>
                  <label className="switch-wrap">
                    <input
                      type="radio"
                      name="tableSizeSmall"
                      key="tableSizeSmall"
                      checked={currentTableSize === tableRowSize.Small}
                      onChange={(e) =>
                        handleTableSizeSelection(tableRowSize.Small)
                      }
                    />
                    <div
                      className="switch switch-small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Small table"
                    >
                      <TableSmallIcon />
                    </div>
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
        ) : null}
      </div>
    ) : null;
  };

  const getForecastTemplate = () => {
    const dateLables = () =>
      data.map((item) => {
        return (
          <th
            scope="col"
            style={{ width: "6rem" }}
            className="text-end"
            key={item.label}
          >
            {item.label}
          </th>
        );
      });

    const rows = () =>
      config.attributeConfigurations.map((c) => {
        return (
          <React.Fragment
            key={"forecast-rows" + c.parent + c.attributeName + c.displayName}
          >
            <tr key={c.attributeName}>
              <th
                scope="col"
                className="name"
                style={{
                  backgroundColor: c.tableRowBackgroundColor,
                  color: c.tableRowTextColor,
                }}
              >
                <div className="flex">
                  <div
                    className={
                      isTrue(config.isForecastTotalEditable)
                        ? "actions small"
                        : ""
                    }
                  >
                    {c.isEditable &&
                    isUserAuthorized(claims.ForecastOverrideWrite) &&
                    isTrue(config.isForecastTotalEditable) &&
                    currentView === forecastViewType.RollingView ? (
                      <button
                        className="link small bold underline"
                        onClick={() => handleEdit(c, actionType.Edit)}
                      >
                        <span>Edit</span>
                      </button>
                    ) : (
                      <span> </span>
                    )}
                    {c.parent === "Projects" ? (
                      <button
                        type="button"
                        className="link float-end"
                        onClick={() => handleShowProjects(c)}
                      >
                        <span
                          className={`arrow-icon ${
                            isProjectRowExpanded(c) === true
                              ? "expanded"
                              : "collapsed"
                          }`}
                          style={{
                            color: c.tableRowTextColor,
                          }}
                        >
                          <ArrowIcon />
                        </span>
                      </button>
                    ) : (
                      <span> </span>
                    )}
                  </div>
                  <div style={{ width: "11rem" }}>
                    <OverFlowToolTip>{c.displayName}</OverFlowToolTip>
                  </div>
                </div>
              </th>
              {data.map((item, index) => {
                return (
                  <td
                    className="text-end"
                    key={index + item.label + c.displayName + c.attributeName}
                    style={{
                      backgroundColor: c.tableRowBackgroundColor,
                      color: c.tableRowTextColor,
                    }}
                  >
                    <div
                      style={{ width: "6rem" }}
                      className={`${
                        (c.attributeName.toLowerCase() ===
                          "commercialoverride" &&
                          item.isOverrideInProcess) ||
                        (c.attributeName.toLowerCase() === "quantityoverride" &&
                          item.isHistoryOverrideInProcess)
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      <OverFlowToolTip>
                        {formatNumber(
                          item[c.attributeName.toLowerCase()],
                          config.decimalPlaces
                        )}
                      </OverFlowToolTip>
                    </div>
                  </td>
                );
              })}
            </tr>
            {c.parent === "Projects" && isProjectRowExpanded(c) === true ? (
              <tr>
                <td colSpan="18" className="p-0">
                  <ProjectTable
                    forecastRequest={forecastRequest}
                    config={config}
                    selectedProjectRow={selectedProjectRow}
                    attributeConfiguration={c}
                    tableSizeCss={getTableSizeCss()}
                  />
                </td>
              </tr>
            ) : null}
          </React.Fragment>
        );
      });

    return (
      <React.Fragment>
        <table
          className={`forecast table ${getTableSizeCss()} table-striped-custom ${
            config.totalTableHeader != null ||
            isTrue(config.enableForecastTableSizeSelection)
              ? "table-header-border"
              : ""
          }`}
        >
          <thead>
            <tr>
              <th scope="col">
                <div className="flex">
                  <div
                    className={
                      isTrue(config.isForecastTotalEditable)
                        ? "actions small"
                        : ""
                    }
                  ></div>
                  <div className="text-start">
                    {config.attributeDisplayNameHeader != null
                      ? config.attributeDisplayNameHeader
                      : "Name"}
                  </div>
                </div>
              </th>
              {dateLables()}
            </tr>
          </thead>
          <tbody>{rows()}</tbody>
        </table>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="col-12" key="forecasttable">
        <div className="card shadow-sm rounded-extra-lg">
          {getForecastHeaderTemplate()}
          <div className="card-body">
            <div className="mb-2 table-responsive scrollbar horizontal">
              <div className="scrollbar-content">{getForecastTemplate()}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-2"></div>
    </React.Fragment>
  );
}

export default ForecastTable;
